import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";
import { blogList } from "../../data/blogs";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/waiting-for-home-prices-to-drop-could-be-a-mistake"
  );

  return (
    <LayoutWrapper
      title="Why Waiting for Home Prices to Drop Could Be a Mistake"
      desc="Many people have held off purchasing a home and waiting for home prices to drop before buying a home. Read on to learn why this could be a mistake."
      headerLogo="../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              Why Waiting for Home Prices to Drop Could Be a Mistake
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>

                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  September 15, 2022
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Why Waiting for Home Prices to Drop Could be a Mistake.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The seller’s market for homes continues to hold strong. This is due to fewer homes
                on the market, supply issues affecting new home construction, and rising rent
                prices. Still, there are good reasons to buy now instead of waiting for the housing
                market to drop.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                The Wait Could Be Long
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you could be certain that the market would change direction soon, putting off
                your home purchase would be worthwhile. But there are signs it could take a bit of
                time.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                One big sign is that a significant reversal of the housing market would require a
                drastic uptick in new homes entering the market, but the inventory isn’t expected to
                increase for two years or more.{" "}
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Mortgages Are Still More Affordable Than Rent
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Another reason why waiting for prices to drop could be a mistake is that continuing
                to pay high rent can put you in a worse financial position. Even though mortgage
                rates have risen, making a payment on a home loan brings you closer to financial
                freedom. This is because each payment adds to the equity you own in your home.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Conversely, rent prices are usually higher than mortgage payments for similar
                properties. Even when factoring in the costs of homeowner’s insurance and property
                taxes, homeowners still tend to pay less than renters.{" "}
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Contractors Are Still Facing Challenges
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                It’s also important to look at the construction trade. Construction companies are
                still dealing with labor shortages, supply chain issues, and the rising costs of
                materials. These factors prevent contractors from building as many new homes as they
                have in past years. As a result, significantly fewer new construction homes will
                enter the market.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The few new homes sold in the coming 12 months will be considerably higher than
                resale homes. The rising prices are due to the higher wages required to attract
                construction workers, the extended length of time it takes to build new homes, and
                the rising costs of wood and other building supplies.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                There Are Favorable Options
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you are ready to buy a home, working with a real estate agent can help you find a
                better deal. They will alert you as soon as a home that matches your needs and
                budget hits the market.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                An experienced agent will also suggest compromises to help you find a great home at
                a better price. For example, consider a home in the suburbs if you work remotely or
                don’t mind a longer commute.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Changing your criteria for buying a home can help you find a home that suits your
                needs and still stay within your budget.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Get the Experienced Home Buying Help You Need Today
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The best way to handle real estate negotiations is to work with an experienced real
                estate professional. <ContactSlideoutLink text="Contact the Selby Team today" />.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
